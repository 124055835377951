@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url('https://fonts.googleapis.com/css2?family=Cuprum&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Crete+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@900&family=Roboto:wght@400;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&family=Roboto+Slab:wght@900&family=Roboto:wght@400;900&display=swap');

.icons {
  color: red;
}

.label {
  display: inline;
  padding: 0.3em 0.6em 0.2em;
  margin: 4px;
  color: white;
  border-radius: 0.25em;
}

.label.Sun {
  background-color: orange;
}

.label.trc {
  background-color:  #0029CE;;
}

.label.rt {
  background-color: #ff2400;
}

.label.ipkeys {
  background-color: #66b2ff;
}

a.IP {
  /* color: #0029CE; */
  font-family: 'Cuprum', sans-serif;
  text-decoration: none
}

.topnav {
  height: 31px;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: -15px;
  overflow-y: hidden;
  left: 0;
  background-color: #f8f8f8;
  overflow-x: hidden;
  padding-top: 15px;
  display: inline;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e7e7e7;
  margin-bottom: 20px;
}

.topnavlink {
  text-align: right;
  float: right;
  bottom: 0;
  font-family: 'Pontano Sans', sans-serif;
  font-size: 16px;
  /* width: 400px; */
  width: 70%;
  /* border: 3px solid green; */
  margin: 5px;
  padding-right: 10px;
}

.linkBox {
  padding-right: 20px;
  display: inline;
}

.imgborder {
  height: 45px;
  width: 45px;
  border-radius: 50%;
}

.imgcenter {
  display: inline-block;
  margin: 0 auto;
}

.mainCV {
  margin-top: 80px;
}

/* This will keep the div in place when screen gets resized. without this the text moves upward on resizing */
.mainCont {
  
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  /* left: 50%;
  right: 50%; */
  /* width: 80%; */
  height: 400px;
  padding: 30px;
  /* border: 3px solid green; */
}

#imgStyle {
  overflow: hidden;
  border-radius: 50%;
}

.blockPosImg {
  display: inline-block;
}

.blockPos {
  width: 70%;
  letter-spacing: .03em;
  line-height: 1.8em;
  display: block;
  /* border: 3px solid blue; */
  padding: 5px;
  /* margin:0 auto; */
}

.blockPosFont {
  letter-spacing: .03em;
  line-height: 1.8em;
}

.blockPosSec {
  width: 300px;
  text-align: center ;
  display: inline;
  /* border: 3px solid blue; */
  padding: 5px;
  margin: 0 auto;
}

.paragraphFont {
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-size: 15px;
  line-height: 35px;
  margin: 0 0 20px;
  width: 100%;
  color: #333;
}

.headerFont {
  /* font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; */
  font-family: 'Open Sans', sans-serif;
  font-size: 32px;
  padding-bottom: 6px;
  margin: 5px 0 20px;
  /* border-bottom: 3px solid #eee; */
}

.codedesign {
  /* font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; */
  font-family: 'IBM Plex Mono', monospace;
  font-size: 32px;
  padding-bottom: 6px;
  margin: 5px 0 20px;
  /* border-bottom: 3px solid #eee; */
}
a.SB {
  /* color: orange ; */
  font-family: 'Cuprum', sans-serif;
  text-decoration: none;
}

a.TR {
  /* color: #0029CE; */
  font-family: 'Cuprum', sans-serif;
  text-decoration: none
}

a.Rutgers {
  /* color: #ff2400; */
  font-family: 'Cuprum', sans-serif;
  text-decoration: none
}

a.IG {
  color: grey;
  font-family: 'Cuprum', sans-serif;
  text-decoration: none
}

a:hover {
  opacity: 0.5;
  text-decoration: underline;
}

.topnavdetail {
  text-align: left;
  float: left;
  font-family: 'Roboto', sans-serif;
  font-size: 22px;
  width: 200px;
  /* border: 3px solid green; */
  margin: 5px;
}

.linkBoxHeading {
  padding-right: 20px;
  padding-right: 20px;
  display: inline;
  text-decoration: none;
  color: black;
}


tr.tr-hover-class:hover {
  background: #e7ecf6 !important;
  cursor: pointer;

}

a.namehover {
  background-image: linear-gradient(
    to right,
    #f8f8f8,
    black 50%,
    #000 50%
  );
  color: '#565957';
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;

  
}

a.namehove:link {
color: #565957;
}

a.namehover:before{
  content: '';
  background: grey;
  display: block;
  position: absolute;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
  color: '#565957';
}

a.namehover:hover {
 background-position: 0;
}

a.namehover:hover::before{
  width: 100%;
}

/* This will keep two divs side by side */
.containDiv
{
  display: flex;
  flex-wrap: wrap;
}

/* This will keep two divs side by side */
.placeDivSideBySide
{
  width: 50%;
  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .text {
      font-size: 16px; /* Decrease font size for smaller screens */
      margin: 10px; /* Adjust margin for spacing */
  }
}

/*  $$$$$$$$   */
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
}

@media screen and (max-height: 450px) {
  .topnavlink {
    padding-top: 15px;
  }
}
::-webkit-scrollbar {display:none;}



.icon-buttons {
  display: flex;
  gap: 10px;
}

.icon-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 8px;
  background-color: "3px solid #eee";
  /* background-color: 'trasnparent'; */
  color: #555;
  font-size: 15px;
  text-decoration: none;
  border: 2px solid #eee; /* Add grey border */
  transition: background-color 0.3s ease;
}

.icon-button:hover {
  background-color: #e0e0e0;
}

.icon-button-location {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 30px;
  border-radius: 8px;
  background-color: "3px solid #eee";
  /* background-color: 'trasnparent'; */
  color: #555;
  font-size: 15px;
  text-decoration: none;
  border: 2px solid #eee; /* Add grey border */
  transition: background-color 0.3s ease;
}


.collapsible .content {
  padding: 6px;
  background-color: #eeeeee;
}
.collapsible .header {
  background-color: #dddddd;
  padding: 6px;
  cursor: pointer;
}
