@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&family=Open+Sans&display=swap');


.headerFont {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
}

.tabContent {
  /* border-bottom: 1px solid #ddd; */
  width: 100%;
} 

.labelProject {
  display: inline;
  padding: 0.3em 0.6em 0.2em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: black;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.labelProject.label-link {
  font-family: 'Roboto', sans-serif;
  background-color: #F1F3F4;
}

.labelProject.label-default {
  font-family: 'Roboto', sans-serif;
  background-color: #EAF6F6;
}


.labelProject.label-ml {
  font-family: 'Roboto', sans-serif;
  background-color: #EAF6F6;
}

.labelProject.label-tab {
  font-family: 'Roboto', sans-serif;
  background-color: #FFE07A;
}

.topnav {
  height: 31px;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: -15px;
  overflow-y: hidden;
  left: 0;
  background-color: #f8f8f8;
  overflow-x: hidden;
  padding-top: 15px;
  display: inline;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #e7e7e7;
  margin-bottom: 20px;
}

.topnavlink {
  text-align: right;
  float: right;
  bottom: 0;
  font-family: 'Pontano Sans', sans-serif;
  font-size: 16px;
  width: 70%;
  margin: 5px;
  padding-right: 10px;
}

.linkBox {
  padding-right: 20px;
  display: inline;
}

.topnavdetail {
  text-align: left;
  float: left;
  font-family: 'Roboto', sans-serif;
  font-size: 22px;
  width: 200px;
  margin: 5px;
}

.imgborder {
  height: 45px;
  width: 45px;
  border-radius: 50%;
}

.imgcenter {
  display: inline-block;
  margin: 0 auto;
}

.main {
  margin-top: 130px;
}

.mainCont {
  display: block;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  left: 50%;
  right: 50%;
  width: 80%;
  padding: 30px;
}

.blockPos {
  width: 97%;
  letter-spacing: .03em;
  line-height: 1.8em;
  display: block;
  padding: 5px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.blockPosFont {
  letter-spacing: .03em;
  line-height: 1.8em;
}

.blockPosSec {
  width: 300px;
  text-align: center;
   display: inline;
  padding: 5px;
  margin: auto;
}

.iconBlock {
  align-items: center;
  float: right;
}

.headerFont {
  font-family: 'Open Sans', sans-serif;
  font-size: 22px;
}

a:hover {
  opacity: 0.5;
  text-decoration: underline;
}

/*  $$$$$$$$   */

.collapsible {

  cursor: pointer;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
}


.content {
  padding: 0 18px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: #f1f1f1;
}


.linkBoxHeading {
  padding-right: 20px;
  padding-right: 20px;
  display: inline;
  text-decoration: none;
  color: black;
}

.headerFontP {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  padding: 10px;

}


.hoverTable{
  width:100%;
  border-collapse:collapse;
}

/* Define the hover highlight color for the table row */
.hoverTable tr:hover {
background-color: #EFF4FF;
}

linkProj {
  color: black;
}

a.linkProj:hover {
  opacity: 0.5;
  text-decoration: none;
}

.labelProject.label-proj-text {
  font-family: 'Lato', sans-serif;
}

.labelProject.label-App {
  font-family: 'Lato', sans-serif;
  color: #5599CA;
  border: 0.8px solid #5599CA;
}

.labelProject.label-System {
  font-family: 'Lato', sans-serif;

  color: #577081;
  background-color: #e7ecf0;
  border: 0.8px solid #95ABBB;
}

.labelProject.label-code {
  font-family: 'Lato', sans-serif;

  color: #7e909d;
  background-color: #d6eeff;
  border: 0.8px solid #95ABBB;
}


.label {
  display: inline;
  padding: 0.3em 0.6em 0.2em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: black;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.tab {
  display: inline-block;
  margin-left: 40px;
}


.label {
  display: inline;
  padding: 0.3em 0.6em 0.2em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: black;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.label.label-link {
  font-family: 'Roboto', sans-serif;
  background-color: #F1F3F4;
}

.label.label-tab {
  font-family: 'Roboto', sans-serif;
  background-color: #FFE07A;
}


.label.label-ipk {
  font-family: 'Lato', sans-serif;
  background-color: #e5e5e4;

  /* font-family: 'Roboto', sans-serif; */
  font-size: 10px;
  color: #919191;

  border: 0.8px solid #919191;
}


.label.label-System {
  font-family: 'Lato', sans-serif;
  /* font-family: 'Roboto', sans-serif; */
  font-size: 10px;
  color: #266ea0;
  background-color: #BADBF2;
  border: 0.8px solid #5599CA;
}


/*  $$$$$$$$   */
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
}

@media screen and (max-height: 450px) {
  .topnavlink {
    padding-top: 15px;
  }
}


.project_tab_desc {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 5px;

}

.labelProject.label-link {
  font-family: 'Lato', sans-serif;
  color: #5599CA;
  border: 0.8px solid #e2c257;
  background-color: #FFE07A;
}

.projectDivFont {
  font-family: 'IBM Plex Mono', monospace;
  font-size: 15px;
  line-height: 25px;
  margin: 0 0 20px;
  width: 100%;
  color: #333;
}

.projects-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center; /* Center the container horizontally */
  padding: 20px; /* Add some padding around the container */
}

.project-box {
  flex: 1 1 45%; /* Adjusted to ensure two boxes per row with some gap */
  max-width: 45%; /* Ensures the boxes don't exceed 45% of the container width */
  border: 1px solid #eee;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  box-sizing: border-box; /* Ensures padding and border are included in the width */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Added shadow */
  border-radius: 15px; /* Make the border more rounded */
}

.project-box:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.project-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.project-labels {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}