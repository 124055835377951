
.btn {

    cursor: pointer;
    font-size: 16px;
    color: black;
    font-weight: 400;
    line-height: 45px;
    /* max-width: 150px; */
    margin: 0 0 2em;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    width: 20%;
    color: red;
    
    @media (min-width: 600px) {
        margin: 0 0.2em 0.3em;

    }

    &:hover {
        text-decoration: none;
    }
}


.btn-5 {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
    outline: 0.3px solid;
    outline-color:#eee;
    outline-color:#c4c4c4;
    outline-offset: 0px;
    text-shadow: none;
    transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
    border-radius: 0.3em;
    color: black;
}

.btn-5:hover {
    border: 0px solid;
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5),
        0 0 20px #ffffff33;
    outline-color: rgba(91, 247, 13, 0);
    outline-offset: 15px;
    color: black;
}

.btnlabel {
  display: inline;
  color: white;
}


.cool-effect {

    position: relative;
    display: inline-block;
    color: #000;
    transition: color 0.3s ease;
  }
  
  .cool-effect::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #565957;
    transform-origin: bottom right;
    transition: transform 0.3s ease;
  }
  
  .cool-effect:hover {
    color: #565957; /* Change color on hover */
  }
  
  .cool-effect:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
    
  }